
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({})
export default class extends Vue {
  @Prop() readonly showDialog!: boolean
  @Prop() readonly userId!: boolean

  private searchInfo = {
    machineryNameCode: '', // 机械名称/编码
    transferDate: '' // 使用日期
  }

  private loading = false
  private tableData = []
  private checkMachineryList= [] // 选中的调用的列表

  @Watch('showDialog')
  onChangeChildValue (newValue: any) {
    // console.log('弹窗状态改变', newValue)
    // this.searchInfo.createUserName = this.userName // 请求列表始终要传这个值
    if (newValue) {
      this.loadData()
    }
  }

  loadData () {
    // console.log('loadData', this.userName)
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhTransferUserByPage,
      {
        ...this.searchInfo,
        wait: this.userId,
        transferDate: this.searchInfo.transferDate
      }).then(res => {
      // console.log('res', res)
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  handleSelectionChange (val: any) {
    if (val.length > 1) {
      (this.$refs.mytable as any).clearSelection();
      (this.$refs.mytable as any).toggleRowSelection(val.pop())
    }
  }

  selectTable (selection: any, row: any) {
    const arr: any = [row]
    this.checkMachineryList = arr
  }

  onSave () {
    if (this.checkMachineryList.length === 0) {
      this.$message.warning('请选择机械')
    } else {
      this.$emit('onCheckList', this.checkMachineryList)
      this.$emit('update:showDialog', false)
    }
  }

  closeDialog () {
    // console.log('关闭')
    this.tableData = []
    this.checkMachineryList = []
    this.searchInfo = {
      machineryNameCode: '', // 机械名称/编码
      transferDate: '' // 使用日期
    }
    this.$emit('update:showDialog', false)
  }
}
