
import { ElForm } from 'element-ui/types/form'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import AddTransfer from './AddTransfer.vue'
@Component({
  components: { AddTransfer }
})

export default class extends Vue {
  @Prop() readonly addShow!: boolean
  @Watch('addShow')
  isAdd (val: string) {
    if (val) {
      this.formData.recordTime = `${this.$dayjs().format('YYYY-MM-DD HH:mm:ss')}`
    }
  }

  @Watch('formData.recordTime')
  WatchBackTime () {
    this.pickerOptions.selectableRange = `'00:00:00 - ${this.$dayjs().format('HH:mm:ss')}`
  }

  // 只能选择当天
  private pickerOptions={
    disabledDate (time: any) {
      // console.log('time', time)
      let sevenAfter = 24 * 3600 * 1000
      sevenAfter = Date.now() - sevenAfter
      return time.getTime() > Date.now() || time.getTime() < sevenAfter
    },
    // 小于当前时间
    selectableRange: `'00:00:00 - ${this.$dayjs().format('HH:mm:ss')}`
  }

  private rules = {
    userId: [
      { required: true, message: '请选择领用人', trigger: ['blur', 'change'] }
    ],
    machineryName: [
      { required: true, message: '请选择机械名称', trigger: ['blur', 'change'] }
    ],
    recordTime: [
      { required: true, message: '请选择领用时间', trigger: ['blur'] }
    ]
  }

  private formData = {
    transferId: '',
    userId: '',
    machineryId: '',
    machineryName: '',
    machineryTypeName: '',
    machineryCode: '',
    machineryBrand: '',
    machineryModel: '',
    recordTime: ''
  }

  private userNameList = [] // 用户名列表
  private checkedMachineryList = [] //  机械名称
  private machineryDialog = false

  created () {
    this.getUserList()
  }

  getUserList () {
    this.$axios.get(this.$apis.personnel.personnelPage, {
      page: '',
      size: ''
    }).then((res) => {
      // console.log('人员列表3', res)
      this.userNameList = res.list
    })
  }

  // 改变领用人
  selectUsername (val: any) {
    // console.log('id', val)
    // 只要改变了就清除数据，因为机械信息会根据用户变化
    this.clearData()
    // 如果选择了值,就给 userId 赋值
    if (val) {
      this.formData.userId = val
    }
  }

  // 点击机械名称
  selectMachineryName () {
    // console.log('出现弹窗2')
    if (!this.formData.userId) {
      this.$message.warning('请选择领用人')
      return
    }
    this.machineryDialog = true
  }

  // 选择机械设备后触发的方法
  onCheckList (data: any) {
    // console.log('选中机械成功2', data)
    this.checkedMachineryList = data
    this.formData.transferId = data[0].transferId
    this.formData.machineryId = data[0].machineryId
    this.formData.machineryName = data[0].machineryName
    this.formData.machineryTypeName = data[0].machineryTypeName
    this.formData.machineryCode = data[0].machineryCode
    this.formData.machineryBrand = data[0].machineryBrand
    this.formData.machineryModel = data[0].machineryModel
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      // console.log('提交数据', this.formData)
      if (valid) {
        // console.log('提交数据', this.formData)
        this.$axios.post(this.$apis.machineryManage.insertYhMachineryRecord, this.formData).then(() => {
          this.$message.success('新增成功')
          this.$emit('closeDialog')
          this.handleClose()
          this.$router.push({ name: 'return' })
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    // this.$emit('closeDialog')
    // this.clearformData()
    this.clearData();
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:addShow', false)
    this.$emit('success')
  }

  // 清空数据
  clearData () {
    this.checkedMachineryList = [] //  机械名称列表
    this.formData = {
      ...{
        transferId: '',
        userId: '',
        machineryId: '',
        machineryName: '',
        machineryTypeName: '',
        machineryCode: '',
        machineryBrand: '',
        machineryModel: ''
      },
      ...{
        recordTime: this.formData.recordTime
      }
    }
  }
}
