

import { Vue, Component } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import Add from './Add.vue'
import Detail from './Detail.vue'
import Return from './return.vue'
@Component({
  components: { Add, Detail, Return }
})
@KeepAlive
export default class InsectPest extends Vue {
  private addShow = false
  private detailShow = false
  private returnShow = false
  private recordId = ''
  private recordTime = [] // 领用时间
  private backTime = []// 归还时间
  private searchInfo = {
    machineryName: '', // 机械名称
    userName: '', // 领用人/归还确认人
    recordTimeStart: '', // 领用时间开始
    recordTimeEnd: '', // 领用时间结束
    backTimeStart: '', // 归还时间开始
    backTimeEnd: '', // 归还时间结束
    projectId: '' // 所属项目
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10
  private projectList = []

  created () {
    if (this.recordIdQuery) {
      this.onDetail(this.recordIdQuery)
    }
    this.loadData()
    this.getProjectList()
  }

  get recordIdQuery () {
    return this.$route.query.recordId as string || ''
  }

  getProjectList () {
    this.$axios.get(this.$apis.project.selectYhProjectByList).then(res => {
      this.projectList = res
    })
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRecordByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  // 去新增页面
  onAdd () {
    this.addShow = true
  }

  onDetail (recordId: any) {
    this.detailShow = true
    this.recordId = recordId
  }

  onReturn (recordId: any) {
    this.returnShow = true
    this.recordId = recordId
  }

  selectTime1 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.recordTimeStart = ''
      this.searchInfo.recordTimeEnd = ''
    } else {
      this.searchInfo.recordTimeStart = this.recordTime[0]
      this.searchInfo.recordTimeEnd = this.recordTime[1]
    }
  }

  selectTime2 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.backTimeStart = ''
      this.searchInfo.backTimeEnd = ''
    } else {
      this.searchInfo.backTimeStart = this.backTime[0]
      this.searchInfo.backTimeEnd = this.backTime[1]
    }
  }
}
