
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'

@Component({
  name: 'Detail',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly detailShow!: boolean
  @Prop() readonly recordId!: string

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']
  private formData = {}
  protected loading = false
  @Watch('detailShow', { immediate: true })
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRecordByRecordId, { recordId: this.recordId }).then(res => {
      // console.log('详情', res)
      this.formData = res || {}
    }).finally(() => {
      this.loading = false
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:detailShow', false)
    this.formData = {}
  }

  // 处理损坏状态
  filterBackState (val: any) {
    if (val === '1') {
      return '未损坏'
    } else if (val === '2') {
      return '损坏'
    } else {
      return '--'
    }
  }
}
