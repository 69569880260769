
import { ElForm } from 'element-ui/types/form'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { MachineReturn } from '@/types/machineryManage'
@Component({
  components: { UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  @Prop() readonly returnShow!: boolean
  @Prop() readonly recordId!: string

  private rules = {
    backUserId: [
      { required: true, message: '请选择归还确认人', trigger: ['blur', 'change'] }
    ],
    backTime: [
      { required: true, message: '请选择归还确时间', trigger: ['blur', 'change'] }
    ]
  }

  private pickerOptions={
    disabledDate: (time: any) => {
      // console.log('111')
    },
    selectableRange: '00:00:00 - 23:59:59'
  }

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  private info = {
    machineryType: '',
    machineryId: '',
    recordId: '',
    recordUserName: ''
  } // 用于展示信息(跳转至新增报修要用到4个字段)

  private formData: MachineReturn = { // 用于提交表格
    recordId: '',
    machineryId: '',
    backUserId: '',
    backTime: '',
    backState: '1',
    fileList: [],
    recordTime: ''
  }

  private userNameList = []
  private backState = [
    {
      value: '1',
      label: '未损坏'
    },
    {
      value: '2',
      label: '损坏'
    }]

  private loading = false

  @Watch('returnShow')
  isDetail (val: string) {
    val && this.getDetail()
  }

  @Watch('formData.backTime', { immediate: false })
  // WatchBackTime (day: string) {
  WatchBackTime (day: string, oldDay: string) {
    // console.log('WatchBackTime', day, oldDay)

    // 领用日期的年月日
    const recordTime = this.formData.recordTime?.substring(0, 10) as string
    const nowTime: any = this.$dayjs().format('YYYY-MM-DD')
    const selectTime: any = day?.substring(0, 10)
    const recordTimeValue = this.formData.recordTime?.substring(11, 19) as string
    const nowTimeValue: any = this.$dayjs().format('HH:mm:ss')
    // const selectTimeValue: any = day?.substring(11, 19)
    // console.log('selectTime', selectTime)
    // console.log('recordTime', recordTime)
    // console.log('nowTime', nowTime)
    // console.log('selectTimeValue', selectTimeValue)
    // console.log('recordTimeValue', recordTimeValue)
    // console.log('nowTimeValue', nowTimeValue)

    // // 存在说明不是初始化渲染，初始化渲染不让走这个判断
    if (recordTime) {
      // 如果年月日没有变化,不改变时间和时间限制
      if (day.substring(0, 10) === oldDay.substring(0, 10)) {
        // console.log('年月日没有变化')
        return
      }

      if (selectTime === recordTime && selectTime === nowTime) {
        // 选择的年月日等于领用时间和当前时间，时分秒要介于两者之间
        // console.log('年月日等于领用和当天日期', day.substring(11, 19))
        this.pickerOptions.selectableRange = `${recordTimeValue} - ${nowTimeValue}`
        this.formData.backTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      } else if (selectTime === recordTime) {
      // 选择的年月日等于领用时间,时分秒要大于领用时间
        // console.log('年月日等于日期')
        this.pickerOptions.selectableRange = `${recordTimeValue} - 23:59:59`
        this.formData.backTime = `${selectTime} 23:59:59}`
      } else if (selectTime === nowTime) {
        // console.log('年月日等于当天')
        this.pickerOptions.selectableRange = `00:00:00 - ${nowTimeValue}`
        this.formData.backTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      } else {
        // console.log('都不等于')
        this.pickerOptions.selectableRange = '00:00:00 - 23:59:59'
        this.formData.backTime = `${selectTime} 23:59:59}`
      }
    }
  }

  getDetail () {
    this.loading = true
    // this.formData.backUserId = this.$store.state.userInfo.userId // 归还确认人：默认为当前人
    this.formData.backTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')// 归还时间：默认为当前时间
    this.formData.recordId = this.recordId
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryRecordByRecordId, { recordId: this.recordId }).then(res => {
      this.getUserList(res.projectId)
      // console.log('详情', res)
      this.formData.machineryId = res.machineryId
      this.formData.recordTime = res.recordTime
      // const str: any = '2023-09-09 13:40:50'
      // this.formData.recordTime = str
      this.info = res || {}
      // 归还时间年月日会根据领用时间变化，这里需要处理 => 大于领用时间，小于当前时间
      this.pickerOptions = {
        disabledDate (time: any) {
          const now = (new Date()).getTime()
          let date: any = new Date(res.recordTime)
          // let date: any = new Date('2023-09-09 13:40:50')
          date = date.getTime()
          const sevenAfter = 24 * 3600 * 1000
          const recordTime = date - sevenAfter
          return time.getTime() < recordTime || time.getTime() > now
        },
        selectableRange: this.isNowDay(res.recordTime)
        // selectableRange: this.isNowDay('2023-09-09 13:40:50')
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 时分秒禁用
  isNowDay (day: string) {
    // console.log('领用时间', day.substring(11, 19))
    // console.log('当天时间', this.$dayjs().format('HH:mm:ss'))
    if (day.substring(0, 10) === this.$dayjs().format('YYYY-MM-DD')) {
      // console.log('和领用时间一致', day.substring(11, 19))
      return `${day.substring(11, 19)} - ${this.$dayjs().format('HH:mm:ss')}`
    } else {
      // console.log('和领用时间不一致')
      return `00:00:00 - ${this.$dayjs().format('HH:mm:ss')}`
    }
  }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userNameList = res || []
    })
  }

  onSubmit () {
    // console.log('提交数据', this.formData)
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        // console.log('通过校验', this.formData)
        delete this.formData.recordTime
        this.$axios.post(this.$apis.machineryManage.updateYhMachineryRecord, this.formData).then(() => {
          this.$message.success('归还成功')
          // this.$emit('closeDialog')
          this.handleClose()
          this.$router.push({ name: 'return' })
        })
      }
    })
  }

  // 归还并报修
  onSubmit2 () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        delete this.formData.recordTime
        this.$axios.post(this.$apis.machineryManage.updateYhMachineryRecord, this.formData).then(() => {
          this.$emit('update:returnShow', false)
          this.$emit('success')
          this.$router.push(
            {
              path: '/machineryManage/repair',
              query: {
                machineryType: this.info.machineryType,
                machineryId: this.info.machineryId,
                recordId: this.info.recordId,
                recordUserName: this.info.recordUserName
              }
            })
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    (this.formData as MachineReturn).fileList.push(file)
  }

  imgRemove (index: number) {
    this.formData.fileList.splice(index, 1)
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.$emit('update:returnShow', false)
    this.$emit('success');
    (this.$refs.formData as ElForm).resetFields()
    this.info = {
      machineryType: '',
      machineryId: '',
      recordId: '',
      recordUserName: ''
    }
    this.formData = {
      recordId: '',
      backUserId: '',
      backTime: '',
      backState: '1',
      machineryId: '',
      fileList: [],
      recordTime: ''
    }
  }
}
